var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-2"},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-card',{staticClass:"pa-1",attrs:{"tile":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : (_vm.defaultView ? 3 : 2)}},[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.defaultView)?_c('v-col',{staticClass:"py-1",attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 4  : 12}},[(_vm.post.image)?_c('v-img',{staticClass:"rounded",attrs:{"src":_vm.post.image,"contain":""}}):_vm._e()],1):_vm._e(),_c('v-col',{class:[_vm.$vuetify.breakpoint.smAndDown ? 'pl-2' : '' ,'py-1'],attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 8 : 12}},[_c('initiator-display',{attrs:{"userId":_vm.post.SourceId,"postDate":_vm.post.publishedDate}})],1)],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : (_vm.defaultView ? 9 : 10)}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"px-2"},[(_vm.displayedAlternativeTitle)?_c('span',{staticClass:"mx-1 font-italic font-weight-light title title-custom alt-title"},[_vm._v(_vm._s(_vm.displayedAlternativeTitle))]):_vm._e(),_c('p',{class:['mr-1', _vm.$vuetify.breakpoint.smAndDown ? 'title-custom-small': 'title-custom',
                    { strikethrough: _vm.displayedAlternativeTitle, 'title': _vm.$vuetify.breakpoint.smAndDown }],domProps:{"innerHTML":_vm._s(_vm.post.title)}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-0",attrs:{"small":"","icon":"","color":"lime lighten-1"},on:{"click":function($event){$event.stopPropagation();return _vm.showTitles($event)}}},on),[_c('v-icon',{staticClass:"xs-icon-font"},[_vm._v("edit")])],1)]}}])},[_c('span',[_vm._v("alternative titles")])]),(!_vm.$vuetify.breakpoint.xsOnly && _vm.defaultView)?_c('p',{class:['mt-1', 'mb-0', 'grey--text', 'text--darken-3', 'body-2', {'caption': _vm.$vuetify.breakpoint.smAndDown}],domProps:{"innerHTML":_vm._s(_vm.post.description)}}):_vm._e()],1)])],1)],1)],1),_c('v-row',{staticClass:"mt-1",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"outlined":"","color":"blue darken-1","block":"","small":"","href":_vm.post.url,"target":"_blank"},on:{"click":function($event){return _vm.logEvent( { 
              type: 'visit_article', 
              data: { 
                target: _vm.post.url,
                source: _vm.clientURL
              }
              })}}},[_vm._v(" Visit Website")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }